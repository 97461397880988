main {
  font-family: 'Arial', sans-serif;
}

h1{
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.service-swiper .swiper-pagination-bullet {
  background: white;
  opacity: 0.5;
}

.service-swiper .swiper-pagination-bullet-active {
  background: #FACC15; /* yellow-400 */
  opacity: 1;
}

.service-swiper .swiper-button-next,
.service-swiper .swiper-button-prev {
  color: #FACC15;
}

/* Services Swiper Styles */
.services-swiper .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  transition: all 0.3s ease;
}

.services-swiper .swiper-pagination-bullet-active {
  background: #FACC15; /* yellow-400 */
  width: 24px;
  border-radius: 5px;
}

.services-swiper .swiper-button-next,
.services-swiper .swiper-button-prev {
  color: #FACC15;
  background: rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
}

.services-swiper .swiper-button-next:hover,
.services-swiper .swiper-button-prev:hover {
  background: rgba(255, 255, 255, 0.2);
}

.services-swiper .swiper-button-next:after,
.services-swiper .swiper-button-prev:after {
  font-size: 18px;
}

/* Add smooth shadow to cards */
.services-swiper .swiper-slide {
  padding: 10px;
}

/* About section animations */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.float-animation {
  animation: float 6s ease-in-out infinite;
}

/* Projects Swiper Styles */
.projects-swiper .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: rgba(30, 64, 175, 0.3);
  opacity: 1;
  transition: all 0.3s ease;
}

.projects-swiper .swiper-pagination-bullet-active {
  background: #FACC15;
  width: 24px;
  border-radius: 5px;
}

.projects-swiper .swiper-button-next,
.projects-swiper .swiper-button-prev {
  color: #1E40AF;
  background: rgba(255, 255, 255, 0.9);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.projects-swiper .swiper-button-next:hover,
.projects-swiper .swiper-button-prev:hover {
  background: #FACC15;
  color: #1E40AF;
}

.projects-swiper .swiper-button-next:after,
.projects-swiper .swiper-button-prev:after {
  font-size: 18px;
}

/* Add smooth shadow to project cards */
.projects-swiper .swiper-slide {
  padding: 10px;
}
